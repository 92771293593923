<template>
  <div>
    <v-dialog
      :value="show"
      :max-width="$dialog.small"
      persistent
      no-click-animation
      v-if="dialogSize == 'small'"
    >
      <v-card>
          <v-card-title>
              <slot name="header"></slot>
          </v-card-title>
          <v-card-text>
            <slot name="body"></slot>

            <div class="mt-5 d-flex gap-7">
              <slot name="footer"></slot>
            </div>
          </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      :value="show"
      :max-width="$dialog.content"
      persistent
      no-click-animation
      v-if="dialogSize == 'content'"
    >
      <v-card>
          <v-card-title>
              <slot name="header"></slot>
          </v-card-title>
          <v-card-text>
            <slot name="body"></slot>

            <div class="mt-5 d-flex gap-7">
              <slot name="footer"></slot>
            </div>
          </v-card-text>
      </v-card>
    </v-dialog>
    
    <v-dialog
      :value="show"
      :max-width="$dialog.medium"
      persistent
      no-click-animation
      v-if="dialogSize == 'medium'"
    >
      <v-card>
          <v-card-title>
              <slot name="header"></slot>
          </v-card-title>
          <v-card-text>
            <slot name="body"></slot>

            <div class="mt-5 d-flex gap-7">
              <slot name="footer"></slot>
            </div>
          </v-card-text>
      </v-card>
    </v-dialog>
    
    <v-dialog
      :value="show"
      :max-width="$dialog.large"
      persistent
      no-click-animation
      v-if="dialogSize == 'large'"
    >
      <v-card>
          <v-card-title>
              <slot name="header"></slot>
          </v-card-title>
          <v-card-text>
            <slot name="body"></slot>

            <div class="mt-5 d-flex gap-7">
              <slot name="footer"></slot>
            </div>
          </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  data() {
    return {

    }
  },

  props: {
    show: Boolean,
    dialogSize: String,
  },

}

</script>
